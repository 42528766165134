import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Subject, retry, take, takeUntil } from 'rxjs';
import { AuthService } from 'src/app/authentication/auth.service';
import { UserAuthenticationTypeList } from 'src/app/authentication/token-enrollment/token-enrollment.component';
import { AuthenticationMethodSettings, ConfigurationSettingsEnum, ConstantService, PassCodeSource, PassCodeSourceType, UserRoles } from 'src/app/services/common/constant.service';
import { ValidationService } from 'src/app/services/common/validation.service';
import { AuthenticationMethod, AuthenticationTypeService, HardwareTokenService, UserCheckEnrollCodeModel, UserCredentialService } from 'src/app/services/identity';
import { OrganizationSettingService } from 'src/app/services/organization';
import { UsersService } from 'src/app/services/user';
import { UserstateService } from 'src/app/services/user/api/userstate.service';
import { environment } from 'src/environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { SecondFaModal } from './second-fa-modal/second-fa-modal';
import { ActivatedRoute } from '@angular/router';
import { FidoHelpersService } from '../../services/common/fido-helpers.service';
import { FidoCredentialOutput, FidoTokenComponent } from './fido-token/fido-token.component';
import { SoftTokenOutput } from './soft-token/soft-token.component';

@Component({
  selector: 'app-enrollment',
  templateUrl: './enrollment.component.html',
  styleUrls: ['./enrollment.component.scss']
})

export class EnrollmentComponent implements OnInit, OnDestroy, OnChanges {

  @Input() userId: number;
  @Input() selOrgId: number = 0;
  @Input() selOrgMnemonic: string = '';
  @Input() username: string = '';
  @Input() disablePrimaryFactor: boolean = false;
  @Input() hidePrimaryfactor: boolean = false;
  @Input() skipPasswordPresence: boolean = false;
  @Input() userActive: boolean = true;

  @Output() savedEnrollment: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild(FidoTokenComponent) fidoTokenComponent: FidoTokenComponent;

  enrollmentFormPrimary: FormGroup;
  enrollmentFormSecondary: FormGroup;
  authTypeData: any;
  tokens = [];
  tokensBuffer = [];
  loading = false;
  bufferSize = 20;
  numberOfItemsFromEndBeforeFetchingMore = 10;
  isEdit = false;
  authenticationMethod: typeof AuthenticationMethod = AuthenticationMethod;
  allowUserCodeLength: number;
  disableSecondFactor: boolean = true;
  buttonDisabled = true;
  authTypeName: string = AuthenticationMethod.Password;
  authTypeName2FA: string = AuthenticationMethod.PassCode;
  defaultAuthType = '';
  defaultAuthType2FA = '';
  interval;
  pushEnabled: boolean = true;
  genericSoftToken: boolean = false;
  pushEnforced: boolean = false;
  pushAllowed: boolean = true;
  softTokenType: string = 'securenvoy';
  authUserType: string = "";
  loginUsername = '';
  isForLoggedInUser = false;
  secondAuthenticationMethod;
  allowSecondFactor = true;
  secondFactorEnforce = true;
  isSmsEnable = true;
  isEmailEnable = true;
  pendingSaving = false;
  authenticationTypeModel: UserAuthenticationTypeList = {
    UserAuthenticationTypeModelList: [
      {
        authenticationTypeId: 0,
        authenticationTypeName: AuthenticationMethod.Password,
        userName: '',
        organizationMnemonic: '',
        sequence: 1,
        jsonData: null,
        isActive: true,
        isDefault: true,
        userCheckEnrollCodeModel: null
      },
      {
        authenticationTypeId: 0,
        authenticationTypeName: '',
        userName: '',
        organizationMnemonic: '',
        sequence: 2,
        jsonData: null,
        isActive: true,
        isDefault: true,
        userCheckEnrollCodeModel: null
      }]
  };
  isSequence1Added: boolean;
  isPasscodeEdit = false;
  isgetPasscodeInfo = false;
  cellVal = '';
  emailVal = '';

  passCodeSourceTypeData = {
    sms: '',
    email: '',
    isSms1FA: true,
    isSms2FA: true,
  }

  yubikeyModelData = {
    key: ''
  }

  userCodeModelData = {
    code: ''
  }

  hardwareModelData = {
    serialId: '',
    source: '',
    code: '',
    recipient: ''
  }

  userCheckEnrollCode: UserCheckEnrollCodeModel = {
    enrollUrl: '',
    checkCode: ''
  }
  isReadyStatus = false;
  secretPushCode: string;
  userRoles: typeof UserRoles = UserRoles;
  isModalHidden = true;
  isSavingPrimary: boolean = false;
  isSavingSecondary: boolean = false;
  disableUpdate: boolean = false;
  selectedUserType = String(UserRoles.User);

  currentFirstFactor: string;
  currentSecondFactor: string;

  authEnforcementSequence: number;
  authEnforcementType: string;

  fidoAllowMultiple: boolean;
  fidoAllowUsernameless: boolean;

  private destroy$: Subject<boolean> = new Subject<boolean>();

  get primaryMethod(): FormControl {
    return this.enrollmentFormPrimary?.get('method') as FormControl;
  }

  get secondaryMethod(): FormControl {
    return this.enrollmentFormSecondary?.get('method') as FormControl;
  }

  get passcode(): FormGroup {
    return this.enrollmentFormPrimary.get('passcode') ?
      this.enrollmentFormPrimary?.get('passcode') as FormGroup : this.enrollmentFormSecondary.get('passcode') as FormGroup;
  }

  get passcodeMethod(): FormControl {
    return this.passcode?.get('passcodeMethod') as FormControl;
  }

  get email(): FormControl {
    return this.passcode?.get('email') as FormControl;
  }

  get sms(): FormControl {
    return this.passcode?.get('sms') as FormControl;
  }

  get pushSoftToken(): FormGroup {
    return this.enrollmentFormPrimary.get('pushSoftToken') ?
      this.enrollmentFormPrimary.get('pushSoftToken') as FormGroup : this.enrollmentFormSecondary.get('pushSoftToken') as FormGroup;
  }

  get checkCode(): FormControl {
    return this.pushSoftToken?.get('checkCode') as FormControl;
  }

  get yubikey(): FormGroup {
    return this.enrollmentFormPrimary.get('yubikey') ?
      this.enrollmentFormPrimary.get('yubikey') as FormGroup : this.enrollmentFormSecondary.get('yubikey') as FormGroup;
  }

  get serialNumber(): FormControl {
    return this.yubikey?.get('serialNumber') as FormControl;
  }

  get staticCode(): FormGroup {
    return this.enrollmentFormPrimary.get('staticCode') ?
      this.enrollmentFormPrimary.get('staticCode') as FormGroup : this.enrollmentFormSecondary.get('staticCode') as FormGroup;
  }

  get staticCodeInput(): FormControl {
    return this.staticCode?.get('staticCodeInput') as FormControl;
  }

  get hardware(): FormGroup {
    return this.enrollmentFormPrimary.get('hardware') ?
      this.enrollmentFormPrimary.get('hardware') as FormGroup : this.enrollmentFormSecondary.get('hardware') as FormGroup;
  }

  get hardwareToken(): FormControl {
    return this.hardware?.get('hardwareToken') as FormControl;
  }

  get passcodeGroup(): FormGroup {
    return this.fb.group({
      passcodeMethod: [null, Validators.compose([Validators.required])],
      email: [null],
      sms: [null],
    });
  }

  get pushSoftTokenGroup(): FormGroup {
    return this.isForLoggedInUser ? this.fb.group({ checkCode: ['', Validators.compose([Validators.required])] }) : this.fb.group({ checkCode: [''] });
  }

  get yubikeyGroup(): FormGroup {
    return this.fb.group({
      serialNumber: [null, Validators.compose([Validators.required, Validators.minLength(this.constantService.yubikeyCharMinLength)])]
    });
  }

  get staticCodeGroup(): FormGroup {
    return this.fb.group({
      staticCodeInput: [null, Validators.compose([Validators.required, Validators.minLength(this.allowUserCodeLength), Validators.pattern(this.validationService.regOnlyNumber)])]
    });
  }

  get hardwareGroup(): FormGroup {
    return this.fb.group({
      hardwareToken: [null, Validators.compose([Validators.required])]
    });
  }

  get fidoTokenGroup(): FormGroup {
    return this.fb.group({
      fidoCredential: [null],
      fidoBackupMethod: [null],
      fidoBackupData: [null]
    });
  }

  get fidoToken(): FormGroup {
    return this.enrollmentFormPrimary.get('fidoToken') ?
      this.enrollmentFormPrimary?.get('fidoToken') as FormGroup : this.enrollmentFormSecondary.get('fidoToken') as FormGroup;
  }

  get fidoCredential(): FormControl {
    return this.fidoToken?.get('fidoCredential') as FormControl;
  }

  get fidoBackupMethod(): FormControl {
    return this.fidoToken?.get('fidoBackupMethod') as FormControl;
  }

  get fidoBackupData(): FormControl {
    return this.fidoToken?.get('fidoBackupData') as FormControl;
  }


  get disablePrimarySave(): boolean {
    return this.enrollmentFormPrimary.invalid || this.disableUpdate || this.isSavingPrimary || this.buttonDisabled || this.defaultAuthType == '' || (this.passCodeSourceTypeData.isSms1FA && !this.isSmsEnable && !!this.passcode) || !this.userActive;
  }

  get disableSecondarySave(): boolean {
    return this.enrollmentFormSecondary.invalid || this.disableUpdate || this.isSavingSecondary || this.buttonDisabled || this.defaultAuthType2FA == '' || (this.passCodeSourceTypeData.isSms2FA && !this.isSmsEnable && !!this.passcode) || !this.userActive;
  }

  constructor(
    private fb: FormBuilder,
    private validationService: ValidationService,
    private constantService: ConstantService,
    private authService: AuthService,
    private hardwareTokenService: HardwareTokenService,
    private userCredentialService: UserCredentialService,
    private toastrService: ToastrService,
    private organizationSettingService: OrganizationSettingService,
    private authTypeService: AuthenticationTypeService,
    private userService: UsersService,
    private userstateService: UserstateService,
    private ngbModal: NgbModal,
    private translateService: TranslateService,
    private activatedRoute: ActivatedRoute,
    private fidoHelpers: FidoHelpersService
  ) {
  }

  ngOnInit() {


    this.enrollmentFormPrimary = this.fb.group({
      method: [{ value: null, disabled: this.disablePrimaryFactor }],
    });
    this.enrollmentFormSecondary = this.fb.group({
      method: [null],
    });

    this.loginUsername = this.authService.userName;
    if (this.username === this.loginUsername) {
      this.isForLoggedInUser = true;
    }
    this.pushEnabled = true;
    this.authUserType = this.authService.userType;

    if (!this.isForLoggedInUser) {
      this.selectedUserType = this.constantService.decode(this.activatedRoute.snapshot.params['userType']);
    }

    if (this.hidePrimaryfactor) {
      this.secondFactorAllow();
    }
    this.getMFASettings(this.selOrgId);
    this.getIsUserPasswordExists();

    this.primaryMethod.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((method) => {
      this.showOption(method, 1, null);
    });

    this.secondaryMethod.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((method) => {
      this.showOption(method, 2, null);
    });

    this.getAuthEnforcement();
  }

  ngOnDestroy() {
    clearInterval(this.interval);
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.disablePrimaryFactor && this.primaryMethod) {
      this.disablePrimaryFactor = changes.disablePrimaryFactor.currentValue;
      this.disablePrimaryFactor ? this.primaryMethod.disable() : this.primaryMethod.enable();
    }
    if (changes.username) {
      this.username = changes.username.currentValue;
    }
  }

  getAuthEnforcement(): void {
    this.userCredentialService.apiUserCredentialGetUserAuthEnforcementStatusOrganizationMnemonicUsernameGet(this.selOrgMnemonic, this.username).subscribe(data => {
      if (data == null) {
        return;
      }
      this.authEnforcementSequence = data.sequence;
      this.authEnforcementType = data.authenticationTypeName;
    })
  }

  getIsUserPasswordExists() {
    // User enrollemt from normal User - don't check for password login - as its obvious that user is logged in with primary auth method
    if (this.skipPasswordPresence) {
      this.getMultiFASetting(this.selOrgId);
    } else {
      // User enrollemt from admin/helpdesk users - check for password login from user
      this.userCredentialService.apiUserCredentialIsUserPasswordExistsUserIdGet(this.userId).pipe(
        takeUntil(this.destroy$)
      ).subscribe(
        data => {
          if (data === true) {
            this.getMultiFASetting(this.selOrgId);
          }
          else {
            this.toastrService.error(this.translateService.instant('Enrollment.UserPasswordLoginError'), '', this.constantService.ToastError);
          }
        });
    }
  }

  getMFASettings(orgId) {
    this.organizationSettingService.apiOrganizationSettingGetSettingsOrganizationIdGet(orgId, ConfigurationSettingsEnum.MFA).pipe(
      takeUntil(this.destroy$)
    ).subscribe((data) => {
      this.allowUserCodeLength = JSON.parse(data.find(x => x.key == 'UserCodeFormat')?.value).MinimumLength;
      this.fidoAllowMultiple = JSON.parse(data.find(x => x.key == 'FidoAllowMultiple')?.value ?? 'false');
      this.fidoAllowUsernameless = JSON.parse(data.find(x => x.key == 'FidoAllowResidentKey')?.value ?? 'false');
      this.softTokenType = data.find(x => x.key == 'SoftTokenAuthenticatorType')?.value ?? 'securenvoy';
      this.pushAllowed = !JSON.parse(data.find(x => x.key == 'DisableSoftTokenPush')?.value ?? 'false');
    });
  }

  getMultiFASetting(orgId) {

    this.organizationSettingService.apiOrganizationSettingGetEnrollSettingsOrganizationIdGet(orgId, this.constantService.AuthEnrollment, this.constantService.MultiFAEnrollment, null, this.userId).pipe(
      takeUntil(this.destroy$)
    ).subscribe(
      data => {
        if (data == null) {
          this.secondAuthenticationMethod = Object.keys(this.authenticationMethod);
          this.allowChangeInfo(this.secondAuthenticationMethod);
        }
        else {
          let secondFASetting = JSON.parse(data[0].value);
          if (Array.isArray(secondFASetting) && secondFASetting.findIndex(x => x === AuthenticationMethodSettings.PushEnforce) < 0) {
            setTimeout(() => {
              this.pushEnforced = true;
              this.softTokenType = 'securenvoy';
            });
          }
          // secondFASetting - gives the values that need to be removed from Auth Methods
          if (secondFASetting) {
            this.secondAuthenticationMethod = Object.keys(this.authenticationMethod).filter(item => !secondFASetting.includes(item));
          } else {
            this.secondAuthenticationMethod = Object.keys(this.authenticationMethod);
          }
          this.allowChangeInfo(this.secondAuthenticationMethod);
          if (secondFASetting.length > 0) {
            for (let i = 0; i < secondFASetting.length; i++) {
              if (secondFASetting[i] === 'SecondFactorAuth') {
                this.allowSecondFactor = false;
              }
              if (secondFASetting[i] === 'SecondFactorEnforce') {
                this.secondFactorEnforce = false;
              }
              if (secondFASetting[i] === PassCodeSource.PassCodeSMS) {
                this.isSmsEnable = false;
              }
              else if (secondFASetting[i] === PassCodeSource.PassCodeEmail) {
                this.isEmailEnable = false;
              }
            }
          }
          else {
            this.disableSecondFactor = false;
          }
        }

        if (this.authService?.userType?.toLowerCase() === UserRoles.User.toLowerCase()) {
          this.secondAuthenticationMethod = this.secondAuthenticationMethod.filter(v => v != 'UserCode');
        }

        this.checkRecordExistance();
      });
  }

  allowChangeInfo(authenticationMethod) {
    if (authenticationMethod && authenticationMethod.length > 0) {
      if (authenticationMethod.length === 1) {
        if (authenticationMethod[0] === AuthenticationMethod.Yubikey) {
          this.buttonDisabled = true;
        }
        else {
          this.buttonDisabled = false;
        }
      }
      else {
        this.buttonDisabled = false;
      }
    }
  }

  checkRecordExistance() {
    if (this.isForLoggedInUser) {
      this.authTypeService.apiAuthenticationTypeGetallUserAuthenticationTypeGet().pipe(
        takeUntil(this.destroy$)
      ).subscribe(
        (data) => {
          this.setUserAuthType(data);
        });
    } else {
      this.authTypeService.apiAuthenticationTypeGetallUserAuthenticationTypeUserNameOrganizationMnemonicGet(this.username, this.selOrgMnemonic).pipe(
        takeUntil(this.destroy$)
      ).subscribe((data) => {
        this.setUserAuthType(data);
      });
    }
  }

  setUserAuthType(data) {
    if (data.length > 0) {
      if (this.isForLoggedInUser === true) {
        for (let i = 0; i < data.length; i++) {
          this.existingUserAuthenticationType(data[i]);
          if (data[i].sequence === 1) {
            this.authTypeName = data[i].authenticationTypeName;
            this.currentFirstFactor = data[i].authenticationTypeName;
            this.authenticationTypeModel.UserAuthenticationTypeModelList[0] = data[i];
          }
          else {
            if (data.length == 1) {
              this.showOption(AuthenticationMethod.Password, 1, null);
            }

            this.authTypeName2FA = data[i].authenticationTypeName;
            this.currentSecondFactor = data[i].authenticationTypeName;
            this.authenticationTypeModel.UserAuthenticationTypeModelList[1] = data[i];
          }
        }
      } else {
        for (let i = 0; i < data.length; i++) {
          this.existingUserAuthenticationType(data[i]);
          if (data[i].sequence === 1) {
            this.authTypeName = data[i].authenticationTypeName;
            this.currentFirstFactor = data[i].authenticationTypeName;
            this.authenticationTypeModel.UserAuthenticationTypeModelList[0] = data[i];
          }
          else {
            if (data.length == 1) {
              this.showOption(AuthenticationMethod.Password, 1, null);
            }
            this.authTypeName2FA = data[i].authenticationTypeName;
            this.currentSecondFactor = data[i].authenticationTypeName;
            this.authenticationTypeModel.UserAuthenticationTypeModelList[1] = data[i];
          }
        }
      }
    } else {
      this.showOption(AuthenticationMethod.Password, 1, null);
    }
    if (!this.isSequence1Added) {
      this.showOption(AuthenticationMethod.Password, 1, null);
    }
  }
  existingUserAuthenticationType(model) {
    if (model.sequence === 2 && model.isActive === true) {
      this.disableSecondFactor = false;
    }

    const response = model;
    if (response.jsonData == null || model.authenticationTypeName.toLowerCase() === this.authenticationMethod.Push.toLowerCase()) {
      this.authTypeData = null;
    }
    else {
      this.authTypeData = JSON.parse(response.jsonData);
    }

    if (response.authenticationTypeName && response.authenticationTypeName.toLowerCase() !== this.authenticationMethod.PassCode.toLowerCase()) {
      this.showOption(response.authenticationTypeName, response.sequence, model);
    }
    else {
      this.isPasscodeEdit = true;
      this.getUserEmailandSMS(response.authenticationTypeName, response.sequence, model);
    }

    if (response.authenticationTypeName.toLowerCase() === this.authenticationMethod.PassCode.toLowerCase()) {
      const methodType = response.sequence + 'FA'
      this.passcodeAuthType(this.authTypeData.source, methodType);
    }
    else if (response.authenticationTypeName.toLowerCase() === this.authenticationMethod.Yubikey.toLowerCase()) {
      this.yubikeyAuthType();
    }
    else if (response.authenticationTypeName.toLowerCase() === this.authenticationMethod.UserCode.toLowerCase()) {
      this.userCodeAuthType();
    }
    else if (response.authenticationTypeName.toLowerCase() === this.authenticationMethod.Hardware.toLowerCase()) {
      this.isEdit = true;
    }
  }

  getUserEmailandSMS(authenticationTypeName, sequence, model) {
    if (!this.isgetPasscodeInfo) {
      this.isgetPasscodeInfo = true;
      this.callUserPasscodeInfo(authenticationTypeName, sequence, model);
    }
    else {
      this.showOption(authenticationTypeName, sequence, model);
    }
  }


  callUserPasscodeInfo(authenticationTypeName, sequence, model) {
    let apiToCall = this.isForLoggedInUser ? this.userService.apiUsersGetUserBasicInfoGet() : this.userService.apiUsersGetUserBasicInfoForUserUserNameGet(this.username, this.selOrgId);
    apiToCall.pipe(
      takeUntil(this.destroy$)
    ).subscribe(
      data => {
        if (data) {
          this.cellVal = data.cell;
          this.emailVal = data.email;
        }
        if (this.passCodeSourceTypeData.sms === '' && this.passCodeSourceTypeData.email === '') {
          this.passCodeSourceTypeData.sms = this.cellVal;
          this.passCodeSourceTypeData.email = this.emailVal;
          if (this.passcode) {
            if (this.sms) {
              this.sms.setValue(this.passCodeSourceTypeData.sms);
            }
            if (this.email) {
              this.email.setValue(this.passCodeSourceTypeData.email);
            }
          }
        }
        if (this.isPasscodeEdit) {
          this.showOption(authenticationTypeName, sequence, model);
        }
      });
  }

  passcodeAuthType(type, methodType) {
    if (methodType === '1FA') {
      if (type.toLowerCase() === PassCodeSourceType.Sms.toLowerCase()) {
        this.passCodeSourceTypeData.isSms1FA = true;
      }
      else {
        this.passCodeSourceTypeData.isSms1FA = false;
      }
    }
    else if (methodType === '2FA') {
      if (type.toLowerCase() === PassCodeSourceType.Sms.toLowerCase()) {
        this.passCodeSourceTypeData.isSms2FA = true;
      }
      else {
        this.passCodeSourceTypeData.isSms2FA = false;
      }
    }

    if (this.authTypeData) {
      if (this.authTypeData.source.toLowerCase() === PassCodeSourceType.Sms.toLowerCase()) {
        this.passCodeSourceTypeData.sms = this.authTypeData.recipient;
        if (this.sms) {
          this.sms.setValue(this.passCodeSourceTypeData.sms);
        }
      }
      else if (this.authTypeData.source.toLowerCase() === PassCodeSourceType.Email.toLowerCase()) {
        this.passCodeSourceTypeData.email = this.authTypeData.recipient;
        if (this.email) {
          this.email.setValue(this.passCodeSourceTypeData.email);
        }
      }
    }

    if (type.toLowerCase() === PassCodeSourceType.Sms.toLowerCase()) {
      if (this.email) {
        this.email.setValidators([]);
        this.email.disable();
      }
      if (this.sms) {
        this.sms.enable();
        this.sms.setValidators(Validators.compose([Validators.required, Validators.pattern(this.validationService.regPhoneNumber)]));
      }
    } else {
      if (this.sms) {
        this.sms.setValidators([]);
        this.sms.disable();
      }
      if (this.email) {
        this.email.enable();
        this.email.setValidators(Validators.compose([Validators.required, Validators.pattern(this.validationService.regEmail)]));
      }
    }
    if (this.email) {
      this.email.updateValueAndValidity();
    }
    if (this.sms) {
      this.sms.updateValueAndValidity();
    }

  }

  yubikeyAuthType() {
    this.yubikeyModelData.key = this.authTypeData.code;
  }

  userCodeAuthType() {
    this.userCodeModelData.code = this.authTypeData.code;
  }

  async showOption(type: string, sequence: number, model) {
    clearInterval(this.interval);
    if (sequence === 1) {
      this.defaultAuthType = this.authenticationTypeModel.UserAuthenticationTypeModelList[0].authenticationTypeName = type;
      if (model == null) {
        model = this.authenticationTypeModel.UserAuthenticationTypeModelList[0];
      }
      this.isSequence1Added = true;
      this.primaryMethod.setValue(type, { onlySelf: true, emitEvent: false });
      this.buildForm(type, 'Primary');
    } else {
      this.defaultAuthType2FA = this.authenticationTypeModel.UserAuthenticationTypeModelList[1].authenticationTypeName = type;
      if (model == null) {
        model = this.authenticationTypeModel.UserAuthenticationTypeModelList[1];
      }
      this.secondaryMethod.setValue(type, { onlySelf: true, emitEvent: false });
      this.buildForm(type, 'Secondary');
    }
    if (type) {
      type = type.toLowerCase();
    }

    if (this.authenticationTypeModel?.UserAuthenticationTypeModelList[0]?.authenticationTypeName?.toLowerCase() !== AuthenticationMethod.Push.toLowerCase()
      && this.authenticationTypeModel?.UserAuthenticationTypeModelList[1]?.authenticationTypeName?.toLowerCase() !== AuthenticationMethod.Push.toLowerCase()) {

      this.userCheckEnrollCode.checkCode = '';
      this.isReadyStatus = false;
      clearInterval(this.interval);
    }

    this.fidoCredential?.clearValidators();
    this.fidoBackupMethod?.clearValidators();
    this.fidoBackupData?.clearValidators();

    if (AuthenticationMethod.PassCode.toLowerCase() === type) {
      if (model.authenticationTypeId <= 0 || this.authTypeData == null) {
        this.authTypeData = {
          auth_type: AuthenticationMethod.PassCode,
          source: '',
          recipient: ''
        }
        this.passCodeSourceTypeData.sms = this.cellVal;
        this.passCodeSourceTypeData.email = this.emailVal;
      }
      else if (this.authTypeData != null) {
        if (this.authTypeData.auth_type.toLowerCase() !== type) {
          this.authTypeData = {
            auth_type: AuthenticationMethod.PassCode,
            source: '',
            recipient: ''
          }
          if (this.passCodeSourceTypeData.sms == null || this.passCodeSourceTypeData.sms === '') {
            this.passCodeSourceTypeData.sms = this.cellVal;
          }
          else if (this.passCodeSourceTypeData.email == null || this.passCodeSourceTypeData.email === '') {
            this.passCodeSourceTypeData.email = this.emailVal;
          }
        }
        else {
          if (this.passCodeSourceTypeData.sms == null || this.passCodeSourceTypeData.sms === '') {
            this.passCodeSourceTypeData.sms = this.cellVal;
          }
          else if (this.passCodeSourceTypeData.email == null || this.passCodeSourceTypeData.email === '') {
            this.passCodeSourceTypeData.email = this.emailVal;
          }
        }
      }

      if (this.passCodeSourceTypeData.sms === '' && this.passCodeSourceTypeData.email === '') {
        this.getUserEmailandSMS(AuthenticationMethod.PassCode, sequence, null);
      } else {
        this.sms.setValue(this.passCodeSourceTypeData.sms);
        this.email.setValue(this.passCodeSourceTypeData.email);
      }

      if (this.passcode) {

        if ((sequence == 1 && this.passCodeSourceTypeData.isSms1FA) || (sequence == 2 && this.passCodeSourceTypeData.isSms2FA)) {
          this.passcodeMethod.setValue('SMS');
        } else {
          this.passcodeMethod.setValue('Email');
        }
        this.email.updateValueAndValidity();
        this.sms.updateValueAndValidity();
      }
    }
    else if (AuthenticationMethod.Push.toLowerCase() === type) {
      if (this.isForLoggedInUser) {

      }
    }

    else if (AuthenticationMethod.Yubikey.toLowerCase() === type) {
      if (model.authenticationTypeId <= 0 || this.authTypeData == null) {
        this.authTypeData = {
          auth_type: AuthenticationMethod.Yubikey,
          code: ''
        }
      } else if (this.authTypeData != null) {
        if (this.authTypeData.auth_type.toLowerCase() !== type) {
          this.authTypeData = {
            auth_type: AuthenticationMethod.Yubikey,
            code: ''
          }
        }
      }
      this.serialNumber.setValue(this.yubikeyModelData.key !== '' ? this.yubikeyModelData.key : this.authTypeData.code);
    }

    else if (AuthenticationMethod.UserCode.toLowerCase() === type) {
      if (model.authenticationTypeId <= 0 || this.authTypeData === null) {
        this.authTypeData = {
          auth_type: AuthenticationMethod.UserCode,
          source: 'Static',
          code: ''
        }
      }
      else if (this.authTypeData !== null) {
        if (this.authTypeData.auth_type === null || this.authTypeData.auth_type && this.authTypeData.auth_type.toLowerCase() !== type) {
          this.authTypeData = {
            auth_type: AuthenticationMethod.UserCode,
            source: 'Static',
            code: ''
          }
        }
      }
      this.staticCodeInput.setValue(this.userCodeModelData.code !== '' ? this.userCodeModelData.code : this.authTypeData.code);
    }
    else if (AuthenticationMethod.Hardware.toLowerCase() === type) {
      this.loadHardwareTokens();
      if (model.authenticationTypeId <= 0 || this.authTypeData === null) {
        this.authTypeData = {
          auth_type: AuthenticationMethod.Hardware,
          source: '',
          code: '',
          recipient: ''
        }
      }
      else if (this.authTypeData !== null) {
        if (this.authTypeData.auth_type === null || this.authTypeData.auth_type && this.authTypeData.auth_type.toLowerCase() !== type) {
          this.authTypeData = {
            auth_type: AuthenticationMethod.Hardware,
            source: '',
            code: '',
            recipient: ''
          }
        }
      }
    }
    else if (AuthenticationMethod.FidoToken.toLowerCase() === type) {


      await new Promise(r => setTimeout(r, 100));
      let currentAuthType;
      if (sequence == 1) {
        currentAuthType = this.currentFirstFactor;
      } else {
        currentAuthType = this.currentSecondFactor;
      }

      if (this.isForLoggedInUser || currentAuthType == 'FidoToken') {
        this.fidoCredential.setValidators(Validators.required);
        this.fidoCredential.updateValueAndValidity();
        this.fidoBackupMethod.setValidators(Validators.required);
        this.fidoBackupMethod.updateValueAndValidity();
        this.fidoBackupData.setValidators(Validators.required);
        this.fidoBackupData.updateValueAndValidity();
      }

      if (currentAuthType == 'FidoToken') {

        this.authTypeData = JSON.parse(model.jsonData);
        this.fidoTokenComponent.setValue(this.authTypeData);
      } else {
        if (this.isForLoggedInUser) {
          //this.fidoTokenComponent.registerFidoToken();
        }
      }
    }
  }

  buildForm(method, enrollmenttype) {
    let enrollmentForm;

    if (enrollmenttype === 'Primary') {
      enrollmentForm = 'enrollmentFormPrimary';
    } else {
      enrollmentForm = 'enrollmentFormSecondary';
    }

    this[enrollmentForm].removeControl('passcode');
    this[enrollmentForm].removeControl('pushSoftToken');
    this[enrollmentForm].removeControl('yubikey');
    this[enrollmentForm].removeControl('staticCode');
    this[enrollmentForm].removeControl('hardware');

    switch (method) {
      case 'PassCode': {
        this[enrollmentForm].addControl('passcode', this.passcodeGroup);
        this.passcodeMethod.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((passcodeMethod) => {
          if (passcodeMethod === 'SMS') {
            this.email.setValidators([]);
            this.email.disable();
            this.sms.enable();
            this.sms.setValidators(Validators.compose([Validators.required, Validators.pattern(this.validationService.regPhoneNumber), Validators.minLength(6)]));
          }

          if (passcodeMethod === 'Email') {
            this.sms.setValidators([]);
            this.sms.disable();
            this.email.enable();
            this.email.setValidators(Validators.compose([Validators.required, Validators.pattern(this.validationService.regEmail)]));
          }
          this.email.updateValueAndValidity();
          this.sms.updateValueAndValidity();
        });
        break;
      }
      case 'Push': {
        this[enrollmentForm].addControl('pushSoftToken', this.pushSoftTokenGroup);
        break;
      }
      case 'Yubikey': {
        this[enrollmentForm].addControl('yubikey', this.yubikeyGroup);
        break;
      }
      case 'UserCode': {
        this[enrollmentForm].addControl('staticCode', this.staticCodeGroup);
        break;
      }
      case 'Hardware': {
        this[enrollmentForm].addControl('hardware', this.hardwareGroup);
        this.hardwareToken.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((hardwareToken) => {
          this.tokenChange(hardwareToken);
        });
        break;
      }
      case 'FidoToken': {
        this[enrollmentForm].addControl('fidoToken', this.fidoTokenGroup);
      }
    }
  }

  loadHardwareTokens() {
    this.hardwareTokenService.apiHardwareTokenGetByUsernameAndUnAssignedGet(this.selOrgId, this.username).pipe(takeUntil(this.destroy$)).subscribe(
      data => {
        this.tokens = data;
        this.tokensBuffer = this.tokens.slice(0, this.bufferSize);
        if (this.isEdit) {
          this.hardwareAuthType();
        }
      });
  }

  hardwareAuthType() {
    const element = this.tokens.find(element => element.type === +this.authTypeData.source && element.seed === this.authTypeData.code && element.id === +this.authTypeData.recipient);
    this.tokenChange(element);
    this.hardwareToken.setValue(element, { onlySelf: true, emitEvent: false });
  }

  tokenChange(item) {
    if (item) {
      this.hardwareModelData.serialId = item.serialId;
      this.hardwareModelData.source = item.type;
      this.hardwareModelData.code = item.seed;
      this.hardwareModelData.recipient = item.id;
    }
  }

  onSearch(event) {
    if (event.term === '') {
      this.tokensBuffer = this.tokens.slice(0, this.bufferSize);
    }
    else {
      const records = this.tokens.filter(x => x.serialId.indexOf(event.term) !== -1);
      this.tokensBuffer = records;
    }
  }

  onScroll({ end }) {
    if (this.loading || this.tokens.length <= this.tokensBuffer.length) {
      return;
    }

    if (end + this.numberOfItemsFromEndBeforeFetchingMore >= this.tokensBuffer.length) {
      this.fetchMoreTokens();
    }
  }

  removeOverlay() {
    document.getElementById(`overlay`).style.display = 'none';
  }

  enlargeImage() {
    this.isModalHidden = false;
  }

  closeQRImage() {
    this.isModalHidden = true;
  }

  secondFactorAllow() {
    this.disableSecondFactor = !this.disableSecondFactor;
    if (this.pendingSaving) {
      return;
    }
    this.pendingSaving = !this.pendingSaving;
    if (this.disableSecondFactor === false) {
      this.showOption(this.authenticationTypeModel.UserAuthenticationTypeModelList[1].authenticationTypeName, 2, this.authenticationTypeModel.UserAuthenticationTypeModelList[1]);
    }
    else {
      if (this.defaultAuthType2FA === '') {
        return;
      }
      this.defaultAuthType2FA = '';
      const modalRef = this.ngbModal.open(SecondFaModal, {
        size: 'md',
        windowClass: 'fadeInUp animated huge',
        backdrop: 'static',
        keyboard: false
      });
      modalRef.result
        .then((result) => {
          if (result === 'yes') {
            let apiToCall = this.isForLoggedInUser ? this.authTypeService.apiAuthenticationTypeInactiveUser2ndFactorGet() : this.authTypeService.apiAuthenticationTypeInactiveUser2ndFactorUserNameOrganizationMnemonicGet(this.username, this.selOrgMnemonic);
            apiToCall.pipe(
              take(1),
              retry(2)
            ).subscribe({
              next: () => {
                this.toastrService.success(this.translateService.instant('Action.Success'));
                this.authenticationTypeModel.UserAuthenticationTypeModelList[1] = {
                  authenticationTypeId: 0,
                  authenticationTypeName: '',
                  userName: '',
                  organizationMnemonic: '',
                  sequence: 2,
                  jsonData: null,
                  isActive: true,
                  isDefault: true,
                  userCheckEnrollCodeModel: null
                }
              },
              error: () => { },
              complete: () => { }
            });
          }
          else {
            this.disableSecondFactor = false;
            this.showOption(this.authenticationTypeModel.UserAuthenticationTypeModelList[1].authenticationTypeName, 2,
              this.authenticationTypeModel.UserAuthenticationTypeModelList[1]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  private fetchMoreTokens() {
    const length = this.tokensBuffer.length;
    const remainingData = this.tokens.slice(length, this.bufferSize + length);
    this.loading = true;
    setTimeout(() => {
      this.loading = false;
      this.tokensBuffer = this.tokensBuffer.concat(remainingData);
    }, 200)
  }


  saveOrUpdate(enrollmentType) {
    this.pendingSaving = false;
    let enrollmentMethod, sequence, savingFlag, modelName, index;
    if (enrollmentType === 'primary') {
      enrollmentMethod = this.primaryMethod.value.toLowerCase();
      sequence = 1;
      savingFlag = 'isSavingPrimary';
      modelName = 'UserAuthenticationTypeModelList';
      index = 0;
    } else {
      enrollmentMethod = this.secondaryMethod.value.toLowerCase();
      sequence = 2;
      savingFlag = 'isSavingSecondary';
      modelName = 'UserAuthenticationTypeModelList';
      index = 1;
    }

    this[savingFlag] = true;
    if (!this.isForLoggedInUser && enrollmentMethod === AuthenticationMethod.Push.toLowerCase()) {
      const authEnforcement: any = {};
      const userData: any = {};
      authEnforcement.authenticationTypeName = AuthenticationMethod.Push;
      authEnforcement.sequence = sequence;
      userData.username = this.username;
      userData.authEnforcement = JSON.stringify(authEnforcement);
      this.userCredentialService.apiUserCredentialUpdateUserAuthEnforcementStatusOrganizationMnemonicPut(this.selOrgMnemonic, userData).pipe(takeUntil(this.destroy$)).subscribe({
        next: () => {
          this.toastrService.success(this.translateService.instant('Action.Success'));
          this[savingFlag] = false;
        },
        error: () => {
          this.toastrService.error(this.translateService.instant('Action.Error'), '', this.constantService.ToastError);
          this[savingFlag] = false;
        },
        complete: () => { }
      }
      );
    }
    else if (!this.isForLoggedInUser && ((sequence == 1 && this.currentFirstFactor != 'FidoToken') || (sequence == 2 && this.currentSecondFactor != 'FidoToken')) && enrollmentMethod === AuthenticationMethod.FidoToken.toLowerCase()) {
      const authEnforcement: any = {};
      const userData: any = {};
      authEnforcement.authenticationTypeName = AuthenticationMethod.FidoToken;
      authEnforcement.sequence = sequence;
      userData.username = this.username;
      userData.authEnforcement = JSON.stringify(authEnforcement);

      this.userCredentialService.apiUserCredentialUpdateUserAuthEnforcementStatusOrganizationMnemonicPut(this.selOrgMnemonic, userData).pipe(takeUntil(this.destroy$)).subscribe({
        next: () => {
          this.toastrService.success(this.translateService.instant('Action.Success'));
          this[savingFlag] = false;
          this.savedEnrollment.emit(true);
        },
        error: () => {
          this.toastrService.error(this.translateService.instant('Action.Error'), '', this.constantService.ToastError);
          this[savingFlag] = false;
        },
        complete: () => { }
      }
      );
    }
    else {
      if (this.authEnforcementSequence > 0) {
        const userData: any = {};
        userData.username = this.username;
        userData.authEnforcement = null;
        this.userCredentialService.apiUserCredentialUpdateUserAuthEnforcementStatusOrganizationMnemonicPut(this.selOrgMnemonic, userData).pipe(takeUntil(this.destroy$)).subscribe({});
      }
      switch (enrollmentMethod) {
        case AuthenticationMethod.Push.toLowerCase(): {
          if (this.isReadyStatus === true) {
            this.authenticationTypeModel[modelName][index].userCheckEnrollCodeModel = null;
          }
          else {
            this.authenticationTypeModel[modelName][index].userCheckEnrollCodeModel = this.userCheckEnrollCode;
          }
          this.authTypeData = null;
          this.authenticationTypeModel[modelName][index].pushEnabled = this.pushEnabled;
          break;
        }
        case AuthenticationMethod.PassCode.toLowerCase(): {
          this.authTypeData = {
            auth_type: AuthenticationMethod.PassCode,
            source: '',
            recipient: ''
          }
          this.authTypeData.recipient = this.passcodeMethod.value === 'SMS' ? this.sms.value : this.email.value;
          this.authTypeData.source = this.passcodeMethod.value === 'SMS' ? PassCodeSourceType.Sms : PassCodeSourceType.Email;
          break;
        }
        case AuthenticationMethod.Yubikey.toLowerCase(): {
          this.authTypeData.code = this.serialNumber.value.substring(0, 12);
          break;
        }
        case AuthenticationMethod.UserCode.toLowerCase(): {
          this.authTypeData = {
            auth_type: AuthenticationMethod.UserCode,
            source: 'Static',
            code: this.staticCodeInput.value
          }
          break;
        }
        case AuthenticationMethod.Hardware.toLowerCase(): {
          this.authTypeData = {
            auth_type: AuthenticationMethod.Hardware,
            source: this.hardwareModelData.source.toString(),
            code: this.hardwareModelData.code,
            recipient: this.hardwareModelData.recipient.toString()
          }
          break;
        }
        case AuthenticationMethod.Password.toLowerCase(): {
          this.authTypeData = null;
          break;
        }
        case AuthenticationMethod.FidoToken.toLowerCase(): {
          const creds = {
            attestations: this.fidoCredential.value,
            backup: {
              method: this.fidoBackupMethod.value,
              recipient: this.fidoBackupData.value
            }
          }
          this.authTypeData = creds;
        }
      }
      this.authenticationTypeModel[modelName][index].jsonData = this.authTypeData ? JSON.stringify(this.authTypeData) : this.authTypeData;
      this.authenticationTypeModel[modelName][index].userName = this.isForLoggedInUser ? '' : this.username;
      let model = this.authenticationTypeModel[modelName][index];

      if(this.isForLoggedInUser){
        this.authTypeService.apiAuthenticationTypeSaveOrUpdateUserAuthenticationTypePost(model).subscribe({
          next: data => {
            if (data === true) {
              this.toastrService.success('User authentication is updated', 'Success');
              this[savingFlag] = false;
              this.savedEnrollment.emit(true);
            } else {
              this.toastrService.error('Push notification is not configured', 'Error', this.constantService.ToastError);
              this[savingFlag] = false;
            }
          },
          error: () => {
            this.toastrService.error('Push notification is not configured', 'Error', this.constantService.ToastError);
            this[savingFlag] = false;
          }
        })
      }else{
        this.authTypeService.apiAuthenticationTypeSaveOrUpdateUserAuthenticationTypeForUserOrganizationMnemonicPost(this.selOrgMnemonic, model)
          .subscribe({
            next: () => {
              this.toastrService.success(this.translateService.instant('Action.Success'));
              this[savingFlag] = false;
              this.savedEnrollment.emit(true);
            },
            error: (e) => {
              if (e.error?.error instanceof SyntaxError == false) {
                this.toastrService.error(this.translateService.instant('Action.Error'), '', this.constantService.ToastError);
              }
              this[savingFlag] = false;
            },
            complete: () => { }
          });
        }
      }

  }

  setFidoCredential(output: FidoCredentialOutput): void {
    this.fidoCredential?.setValue(output.attestation);
    this.fidoBackupMethod?.setValue(output.backupMethod);
    this.fidoBackupData?.setValue(output.backupData);
  }

  handleSoftTokenOutput(output: SoftTokenOutput) {
    this.userCheckEnrollCode.enrollUrl = output.enrolUrl;
    this.pushEnabled = output.pushEnabled;
    if (output.hasAutoEnrolled == true) {
      this.checkCode.disable();
      this.isReadyStatus = true;
      this.buttonDisabled = false;
      this.authTypeData = null;
    }
    else {
      this.checkCode.enable();
      this.checkCode.setValue(output.checkCode);
      this.userCheckEnrollCode.checkCode = output.checkCode;
    }


  }
}
