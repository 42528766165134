/**
 * Conditional Access Microservice
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { EndpointAgent } from './endpointAgent';


export interface EndpointAgentPagedResult { 
    currentPage?: number;
    pageSize?: number;
    totalItems?: number;
    totalPages?: number;
    result?: Array<EndpointAgent> | null;
}

