import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/authentication/auth.service';
import { ConstantService, UserRoles } from 'src/app/services/common/constant.service';
import { AgentService, AuthenticationType, SyslogClientModel, SyslogClientService } from 'src/app/services/organization';
import { HasPermissionService } from 'src/app/services/common/has-permission.service';
import { ValidationService } from 'src/app/services/common/validation.service';

@Component({
  selector: 'app--client-form',
  templateUrl: './syslog-client-form.component.html',
  styleUrls: ['./syslog-client-form.component.scss'],
})
export class SyslogClientFormComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];
  private syslogClientId: number;

  activeTab: string = 'configuration';
  agents: any[];
  originalAgentID = null;

  public syslogClientModel: SyslogClientModel = {};

  constructor(
    private authService: AuthService,
    private constantService: ConstantService,
    private translate: TranslateService,
    private syslogClientService: SyslogClientService,
    private agentService: AgentService,
    private toastrService: ToastrService,
    private activatedRoute: ActivatedRoute,
    private validationService: ValidationService,
    private router: Router
  ) { }

  ngOnInit() {

    this.syslogClientId = +this.constantService.decode(this.activatedRoute.snapshot.params.syslogClientId);

    this.activatedRoute.queryParamMap.subscribe((params) => {
      this.testConnection(false);
    });
  }


  setActiveTab(tab: string) {
    this.activeTab = tab;
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: { activeTab: tab },
    });
  }

  private getClientInfo() {
    this.syslogClientService
      .apiSyslogClientIdOrganizationIdGet(this.syslogClientId, this.authService.organizationId)
      .subscribe((data) => {
        this.syslogClientModel = data;

        this.originalAgentID = this.syslogClientModel.agentID = this.agents.find(v => JSON.parse(v.syslogClientID)?.find(x => x == this.syslogClientModel.syslogClientId))?.agentID;
      });
  }


  submit(clientForm, isMapping?) {

    if (this.syslogClientModel.port != null) {
      this.syslogClientModel.port = +this.syslogClientModel.port;
    } else {
      this.syslogClientModel.port = 514;
    }

    const syslogClientModel = this.constantService.detachObject(
      this.syslogClientModel
    );


    if (this.syslogClientId) {
      let modelRemove = JSON.parse(JSON.stringify(syslogClientModel));
      modelRemove.agentID = this.originalAgentID;

      this.syslogClientService.apiSyslogClientRemoveSyslogAgentIdOrganizationIdPut(this.syslogClientId, this.authService.organizationId, modelRemove)
        .subscribe((data) => {
          this.updateSyslogClient(syslogClientModel, isMapping);
        });
    } else {
      this.createSyslogClient(syslogClientModel, clientForm);
    }



  }

  private createSyslogClient(syslogClientModel: any, clientForm: any) {

    this.syslogClientService
      .apiSyslogClientOrganizationIdOrganizationMnemonicPost(
        this.authService.organizationId,
        this.constantService.getSubdomain(),
        syslogClientModel
      )
      .subscribe({
        next: (data) => {
          this.toastrService.success(
            this.translate.instant('Action.Success')
          );
          this.back();
        },
        error: (e) => {
          if (e.error === 'Syslog.Error.IPUsed') {
            clientForm.form.controls['ipAddress'].setErrors({ isUsed: true });
          }
        },
      });
  }

  private updateSyslogClient(syslogClientModel: any, isMapping: any) {
    this.syslogClientService
      .apiSyslogClientIdOrganizationIdPut(
        this.syslogClientId,
        this.authService.organizationId,
        syslogClientModel
      )
      .subscribe((data) => {
        this.toastrService.success(
          this.translate.instant('Action.Success')
        );
        this.back();

      });
  }

  private back() {
    this.router.navigate(['premises',
      'syslog-client']);
  }

  testConnection(showInfo = true): void {

    this.agentService.apiAgentByOrgKeyOrganizationMnemonicGet(this.constantService.getSubdomain()).subscribe(data => {
      this.agents = data;

      if (this.syslogClientId) {
        this.getClientInfo();

      } else {
        this.syslogClientModel.port = 514;
      }

    });

  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
