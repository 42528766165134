/**
 * Organization Microservice
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface UserModel { 
    userID?: number;
    username?: string | null;
    firstName?: string | null;
    middleName?: string | null;
    lastName?: string | null;
    organizationID?: number;
    managedByOrgID?: number;
    email?: string | null;
    createdBy?: string | null;
    createdDate?: string;
    modifiedBy?: string | null;
    modifiedDate?: string;
    isActive?: boolean;
    phone?: string | null;
    cell?: string | null;
    password?: string | null;
    countryID?: number | null;
    isMustChangePassword?: boolean;
    organizationMnemonic?: string | null;
    isDeleted?: boolean;
    userType?: string | null;
    isMobileNumberVerified?: boolean;
}

