/**
 * Conditional Access Microservice
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { EndpointAgentIpAddress } from './endpointAgentIpAddress';


export interface EndpointAgent { 
    id?: string | null;
    organizationId?: number;
    username?: string | null;
    machineName?: string | null;
    osPlatform?: string | null;
    os?: string | null;
    osVersion?: string | null;
    domainName?: string | null;
    firstSeenUtc?: string;
    lastSeenUtc?: string;
    ipAddresses?: Array<EndpointAgentIpAddress> | null;
}

