import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ConstantService } from 'src/app/services/common/constant.service';
import { DataTableDirective } from 'angular-datatables';

/* 
  Notes for using this component

  1. Give nessecery settings by inputing a DataTableConfig
  2. tableOptions will overwrite the default options if provided
  3. Add 'className' and 'title' to tableColumns items for html display
  4. Use .bind(this) for row-click and button behaviour

*/

export class DataTableConfig {
  // Required
  tableID: string;
  loadTableData: (dataTablesParameters: any, callback: any) => void;
  tableColumns: any[];

  // Optional
  tableOptions?: any;
  tableButtons?: any[] = [];
  tableRowCallback?: any[] = [];

  constructor(datatableConfig?: Partial<DataTableConfig>) {
    Object.assign(this, datatableConfig);
  }
}

@Component({
  selector: 'app-datatable',
  templateUrl: './datatable.component.html',
  styleUrls: ['./datatable.component.scss'],
})
export class DatatableComponent implements OnInit {

  @ViewChild(DataTableDirective, { static: false }) datatableElement: DataTableDirective;
  
  // TODO: add @Input({ required: true }) after angular 16
  @Input() config: DataTableConfig;

  private selectedRows: any[] = [];
  private lastPage: number = 0;
  private lastSearchText = '';
  private pageLength = 10;

  dtOptions: any = {};

  constructor(private constantService:ConstantService) { }

  ngOnInit(): void {
    this.initializeDataTable();
  }

  public getSelectedRows(){
    return this.selectedRows;
  }

  public reload(): void {
    this.selectedRows = [];
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.ajax.reload();
    });
  }

  private initializeDataTable() {
    const defaultOptions = {
      language: this.constantService.TableLang,
      info: true,
      pagingType: 'full_numbers',
      pageLength: this.pageLength,
      displayStart: this.lastPage,
      search: { search: this.lastSearchText },
      autoWidth: false,
      ordering: true,
      order: [[0, 'asc']],
      lengthMenu: ['5', '10', '20', '50'],
      dom: 'lBfrtip',
      select: {},
      serverSide: true,
      processing: true,
      stateSave: true,
      stateSaveCallback: this.constantService.saveTableState,
      stateLoadCallback: this.constantService.loadTableState,
    };

    this.dtOptions = { ...defaultOptions, ...this.config.tableOptions};

    if(this.dtOptions.select.style){
      this.dtOptions.order = [[1, 'asc']];
    }

    this.dtOptions.columns = this.dtOptions.select.style 
      ? [
          {
            data: null,
            className: 'select-checkbox wd-5p',
            name: 'isSelect',
            targets: 0,
            defaultContent: '',
            orderable: false,
            sortable: false,
            searchable: false,
            render: () => '<span class="invisible"></span>',
          },
          ...this.config.tableColumns,
        ]
      : this.config.tableColumns;

    this.dtOptions.buttons = this.config.tableButtons.map(button => {
      return {
        text: button.text,
        attr: button.attr,
        action: (e, dt, node, config) => {
          button.action(e, dt, node, config);
        }
      };
    }),

    this.dtOptions.ajax = (dataTablesParameters: any, callback) => {
      this.config.loadTableData(dataTablesParameters, callback);
    };

    this.dtOptions.rowCallback = (row: Node, data: any[] | Object, index: number) => {
      if(this.dtOptions.select.style){
        if (this.selectedRows.findIndex(row => JSON.stringify(row) === JSON.stringify(data)) >= 0) {
          $(row).addClass('selected');
        }
      }
      
      this.config.tableRowCallback.forEach(action => {
        const element = $(action.selector, row);
        element.off('click');
        element.on('click', (event) => {
          event.stopPropagation(); // avoid other actions
          action.callback(data);
        });
      });

      if(this.dtOptions.select.style){
        const selector = this.dtOptions.select.selector || 'td';
        $(selector, row).off('click');
        $(selector, row).on('click', () => {
          this.updateSelectedRows(data);
        });
      }
      return row;
    };

    if(this.dtOptions.select.style){
      this.dtOptions.drawCallback = function (settings) {
        const api = this.api();
        api.rows().every(function (rowIdx, tableLoop, rowLoop) {
          if ($(api.row(rowIdx).node()).hasClass('selected')) {
            api.row(rowIdx).select();
          }
        });
      };
    }
  }

  private updateSelectedRows(data: any) {
    if(this.dtOptions.select.style == 'single'){
      this.selectedRows = [data];
    }

    if(this.dtOptions.select.style == 'multi'){
      const i = this.selectedRows.findIndex(row => JSON.stringify(row) === JSON.stringify(data));
      if (i >= 0) {
        this.selectedRows.splice(i, 1);
      } else {
        this.selectedRows.push(data);
      }
    }
  }

}
