/**
 * Organization Microservice
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface SyslogClientModel { 
    syslogClientId?: number;
    organizationId?: number;
    mnemonic?: string | null;
    friendlyName?: string | null;
    ipAddress?: string | null;
    sharedSecret?: string | null;
    port?: number | null;
    defaultDomain?: string | null;
    createdBy?: string | null;
    createdDate?: string;
    modifiedBy?: string | null;
    modifiedDate?: string;
    isActive?: boolean;
    agentID?: string | null;
}

