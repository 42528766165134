import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { DataTableDirective } from 'angular-datatables';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/authentication/auth.service';
import {
  ConstantService,
  UserRoles,
} from 'src/app/services/common/constant.service';
import { HasPermissionService } from 'src/app/services/common/has-permission.service';
import {
  OrganizationSettingService,
  SyslogClientService,
} from 'src/app/services/organization';
import { UsersService } from 'src/app/services/user';
import { ConfirmDialogComponent } from 'src/app/shared/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-syslog-client',
  templateUrl: './syslog-client.component.html',
  styleUrls: ['./syslog-client.component.scss'],
})
export class SyslogClientComponent implements OnInit, OnDestroy {
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: any = DataTableDirective;
  private subscriptions: Subscription[] = [];
  private dtOptions: any = {};
  private lastPage: number = 0;
  private lastSearchText = '';
  private pageLength = 10;
  constructor(
    protected ngbModal: NgbModal,
    private authService: AuthService,
    private constantService: ConstantService,
    private translate: TranslateService,
    private usersService: UsersService,
    private toastrService: ToastrService,
    private syslogClientService: SyslogClientService,
    private organizationSettingService: OrganizationSettingService,
    private permission: HasPermissionService,
    private router: Router
  ) { }

  ngOnInit() {
    this.dtOptions = {
      language: this.constantService.TableLang,
      info: true,
      pagingType: 'full_numbers',
      pageLength: this.pageLength,
      displayStart: this.lastPage,
      search: { search: this.lastSearchText },
      autoWidth: false,
      ordering: true,
      lengthMenu: ['5', '10', '20', '50'],
      dom: 'lBfrtip', // Declare the use of the extension in the dom parameter
      buttons: this.permission.allowAccess([
        UserRoles.AdminUser,
        UserRoles.MspAdmin,
        UserRoles.SuperAdmin,
      ])
        ? [
          {
            text:
              '<i class="fa fa-plus mr-1"></i>' +
              this.translate.instant('Button.Add'),
            attr: {
              class: 'btn btn-xs btn-primary ml-4',
              id: 'btnImport',
            },
            action: (e, dt, node, config) => {
              this.addClient();
            },
          },
        ]
        : [],
      columns: [
        { data: 'friendlyName', searchable: true, name: 'friendlyName' },
        { data: 'ipAddress', searchable: false, name: 'ipAddress' },
        { data: 'port', searchable: false, name: 'port' },
        {
          data: null,
          orderable: false,
          sortable: false,
          searchable: false,
          defaultContent: this.permission.allowAccess([
            UserRoles.AdminUser,
            UserRoles.MspAdmin,
            UserRoles.SuperAdmin,
          ])
            ? '<i class="btnDelete fa fa-trash"></i>'
            : '',
        },
      ],
      serverSide: true,
      processing: true,
      stateSave: true,
      stateSaveCallback: this.constantService.saveTableState,
      stateLoadCallback: this.constantService.loadTableState,
      ajax: (dataTablesParameters: any, callback) => {
        this.loadClients(dataTablesParameters, callback);
      },
      rowCallback: (row: Node, data: any[] | Object, index: number) => {
        $('.btnDelete', row).off('click');
        $('.btnDelete', row).on('click', () => {
          this.confirmDelete(data);
          return false;
        });

        const self = this;
        $('td', row).off('click');
        $('td', row).on('click', () => {
          self.viewDetail(data);
        });
        return row;
      },
    };
  }

  private confirmDelete(data) {
    const modalRef = this.ngbModal.open(ConfirmDialogComponent, {
      size: 'md',
      windowClass: 'fadeInUp animated huge',
      backdrop: 'static',
      keyboard: false,
    });

    modalRef.result.then((userResponse: boolean) => {
      if (userResponse) {
        this.syslogClientService
          .apiSyslogClientIdOrganizationIdDelete(
            data.syslogClientId,
            this.authService.organizationId
          )
          .subscribe(() => {
            this.toastrService.success(
              this.translate.instant('Action.Success')
            );
            this.reload();
          });
      }
    });
  }

  private reload() {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.ajax.reload();
    });
  }

  private loadClients(dataTablesParameters: any, callback: any) {
    this.lastPage = dataTablesParameters.start;
    this.lastSearchText = dataTablesParameters.search.value;

    let currentPage =
      Math.round(dataTablesParameters.start / dataTablesParameters.length) + 1;
    let sortColumn =
      dataTablesParameters.columns[dataTablesParameters.order[0].column].data +
      ' ' +
      dataTablesParameters.order[0].dir;
    let searchCriteria: string[] = [];

    this.dtOptions.columns?.forEach((element) => {
      let andOr = searchCriteria.length ? 'or' : null;
      if (element.searchable) {
        searchCriteria.push(
          JSON.stringify({
            field: element.name,
            operator: 'contains',
            value: dataTablesParameters.search.value,
            andOr: andOr,
            writable: true,
          })
        );
      }
    });

    this.syslogClientService
      .apiSyslogClientGetPagedDataOrganizationIdGet(
        this.authService.organizationId,
        sortColumn,
        currentPage,
        dataTablesParameters.length,
        searchCriteria
      )
      .subscribe({
        next: (result) => {
          callback({
            recordsTotal: result.totalItems,
            recordsFiltered: result.totalItems,
            data: result.result,
          });
        },
        error: (err) => {
          this.dtOptions.processing = false;
        },
      });
  }

  private addClient() {
    this.router.navigate(['premises', 'add', 'syslog-client']);
  }

  private viewDetail(data) {
    this.router.navigate([
      'premises',
      'syslog-client',
      this.constantService.encode(data.syslogClientId),
    ]);
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
