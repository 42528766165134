import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbAccordion } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../../environments/environment';
import { AuthService } from '../../../authentication/auth.service';
import { SharedAuthenticationService } from '../../../authentication/shared/shared-authentication.service';
import { ConstantService } from '../../../services/common/constant.service';
import { ValidationService } from '../../../services/common/validation.service';
import { AuthenticationMethod, AuthenticationTypeModel, AuthenticationTypeService, UserCredentialService } from '../../../services/identity';
import { OrganizationSettingService } from '../../../services/organization';
import { UsersService } from '../../../services/user';

@Component({
  selector: 'app-soft-token',
  templateUrl: './soft-token.component.html',
  styleUrls: ['./soft-token.component.scss']
})
export class SoftTokenComponent implements OnInit, OnDestroy {

  mainForm: FormGroup;
  secretPushCode: string;
  interval: NodeJS.Timeout;

  hasAutoEnrolled = false;
  isSaving = false;
  isModalHidden: boolean = true;
  @Input() pushAllowed: boolean = true;
  @Input() pushEnabled: boolean = true;
  @Input() pushEnforced: boolean = false;
  @Input() authenticatorType: string;

  chosenAuthenticatorType: string = 'securenvoy';
  isThirdParty: boolean = false;
  gettingQr = false;

  @Output() output: EventEmitter<SoftTokenOutput> = new EventEmitter<SoftTokenOutput>();

  isSecondFactor: string;

  authenticationType: AuthenticationTypeModel = {
    authenticationTypeId: 0,
    authenticationTypeName: AuthenticationMethod.Push,
    userName: '',
    organizationMnemonic: '',
    sequence: 0,
    jsonData: null,
    isActive: true,
    isDefault: true,
    userCheckEnrollCodeModel: {
      enrollUrl: '',
      checkCode: ''
    },
    pushEnabled: true
  };


  @ViewChild('acc') accordion: NgbAccordion;

  constructor(
    private authTypeService: AuthenticationTypeService,
    public constantService: ConstantService,
    public validationService: ValidationService,
  ) { }

    ngOnDestroy(): void {
      clearInterval(this.interval);
    }

  ngOnInit(): void {
    this.createForm();
    if (this.pushEnforced && this.pushAllowed) {
      this.mainForm.controls.pushEnabled.disable();
      this.authenticatorType = 'securenvoy';
    } else if (!this.pushAllowed) {
      this.pushEnabled = false;
      this.mainForm.controls.pushEnabled.disable();
      this.mainForm.controls.pushEnabled.setValue(false);
    }

    
    if (this.authenticatorType != 'thirdparty') {
      this.startCheckAutoEnrolTimer(this.authenticationType);
    }

    if (this.authenticatorType != 'both') {
      this.chosenAuthenticatorType = this.authenticatorType;
      this.isThirdParty = this.chosenAuthenticatorType == 'thirdparty';
    }
    this.getUserSeedCode();
  }

  createForm() {
    this.mainForm = new FormGroup({
      checkCode: new FormControl({ value: null, disabled: this.hasAutoEnrolled }, [Validators.maxLength(8)]),
      pushEnabled: new FormControl({ value: this.pushEnabled, disabled: this.pushEnforced },),
      authenticatorType: new FormControl({ value: this.chosenAuthenticatorType, disabled: this.authenticatorType != 'both'})
    });
    this.mainForm.controls.checkCode.valueChanges.subscribe(x => {
      let output: SoftTokenOutput = {
        hasAutoEnrolled: false,
        enrolUrl: this.authenticationType.userCheckEnrollCodeModel.enrollUrl,
        checkCode: x,
        pushEnabled: this.pushEnabled
      };
      this.output.emit(output);
    })
    this.mainForm.controls.authenticatorType.valueChanges.subscribe(x => {
      this.chosenAuthenticatorType = x;
      this.isThirdParty = this.chosenAuthenticatorType == 'thirdparty';
      this.getUserSeedCode();
      clearInterval(this.interval);
      if (this.chosenAuthenticatorType == 'securenvoy') {
        this.startCheckAutoEnrolTimer(this.authenticationType);
      }
    })
  }


  startCheckAutoEnrolTimer(model) {
    this.interval = setInterval(() => {
      this.checkAutoEnrol(model);
    }, environment.pushCheckTime * 1000);
  }

  checkAutoEnrol(model) {
    this.authTypeService.apiAuthenticationTypeUserEnrollReadyStatusGet(!this.pushEnabled).subscribe(
      data => {
        if (data === true) {
          clearInterval(this.interval);
          this.hasAutoEnrolled = true;
          this.mainForm.controls.checkCode.disable();
          model.userCheckEnrollCodeModel = {};
          this.accordion?.collapseAll();
          this.closeQrImage();
          const element = document.getElementById('target');
          if (element) {
            this.scroll(element);
          }
          document.getElementById('overlay').style.display = 'block';
          let output: SoftTokenOutput = {
            enrolUrl: this.authenticationType.userCheckEnrollCodeModel.enrollUrl,
            hasAutoEnrolled: true,
            pushEnabled: this.pushEnabled
          };
          this.output.emit(output);
        }
      });
  }

  scroll(el: HTMLElement) {
    el.scrollIntoView();
  }

  removeOverlay() {
    document.getElementById('overlay').style.display = 'none';
  }

  getUserSeedCode() {
    this.gettingQr = true;
    this.authTypeService.apiAuthenticationTypeGetUserSeedUrlGet(!this.pushEnabled, this.chosenAuthenticatorType == 'thirdparty').subscribe(
      data => {
        this.authenticationType.userCheckEnrollCodeModel.enrollUrl = data.link;
        const response1 = this.authenticationType.userCheckEnrollCodeModel.enrollUrl.split('?');
        const response2 = response1[1].split('&');
        const response3 = response2[0].split('=');
        this.secretPushCode = response3[1];
        const regAddSpaceCharacterInput = this.secretPushCode.length > 13 ? this.validationService.regAddSpaceCharacterInputThirdParty : this.validationService.regAddSpaceCharacterInput
        this.secretPushCode = this.secretPushCode.replace(regAddSpaceCharacterInput, this.validationService.regAddSpaceCharacterOutput);
        this.gettingQr = false;
      });
  }

  enlargeImage() {
    if (!this.gettingQr) {
      this.isModalHidden = false;
    }
  }

  closeQrImage() {
    this.isModalHidden = true;
  }

  changePushEnabled() {
    this.pushEnabled = !this.pushEnabled;
    this.getUserSeedCode();
  }

}

export interface SoftTokenOutput {
  hasAutoEnrolled: boolean,
  checkCode?: string,
  enrolUrl?: string,
  pushEnabled: boolean
}
