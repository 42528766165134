/**
 * User Microservice
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UserCustomAttributeModel } from './userCustomAttributeModel';
import { UserAliasModel } from './userAliasModel';


export interface UserModel { 
    userId?: number;
    username?: string | null;
    firstName?: string | null;
    middleName?: string | null;
    lastName?: string | null;
    organizationId?: number | null;
    isLocked?: boolean;
    isTemporarilyLocked?: boolean;
    password?: string | null;
    email?: string | null;
    udsystemGroupId?: number | null;
    createdBy?: string | null;
    createdDate?: string;
    modifiedBy?: string | null;
    modifiedDate?: string;
    isActive?: boolean | null;
    phone?: string | null;
    cell?: string | null;
    fax?: string | null;
    streetAddress?: string | null;
    pobox?: string | null;
    city?: string | null;
    state?: string | null;
    postalCode?: string | null;
    countryId?: number | null;
    jobTitle?: string | null;
    manager?: string | null;
    department?: string | null;
    costCenter?: string | null;
    location?: string | null;
    workCountry?: string | null;
    employeeNumber?: string | null;
    employeeType?: string | null;
    distinguishedName?: string | null;
    immutableId?: string | null;
    organizationMnemonic?: string | null;
    isDeleted?: boolean;
    tokenState?: string | null;
    userType?: string | null;
    sourceDirectoryID?: number | null;
    modifiedByDirectoryID?: number | null;
    enrollmentEmailPending?: boolean;
    parentUserId?: number | null;
    isAutoGeneratedPassword?: boolean;
    mustChangePassword?: boolean;
    ignorePasswordHistory?: boolean;
    disableAt?: string | null;
    isMobileNumberVerified?: boolean;
    linkedUsers?: Array<UserModel> | null;
    customerAttributes?: Array<UserCustomAttributeModel> | null;
    aliases?: Array<UserAliasModel> | null;
}

