/**
 * User Microservice
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UserAliasPatternErrorViewModel } from './userAliasPatternErrorViewModel';


export interface UserAliasPatternViewModel { 
    id?: string | null;
    name?: string | null;
    matchPattern?: string | null;
    replacementPattern?: string | null;
    status?: string | null;
    errors?: Array<UserAliasPatternErrorViewModel> | null;
}

